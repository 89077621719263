<template>
  <div id="user-view">
    <v-row>


      <v-col
        cols="12"
      >
        <v-tabs
          v-model="userTab"
          show-arrows
          class="user-tabs"
        >
          <v-tab
            value="profile"
            href="#profile"
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ mdiAccountOutline }}
            </v-icon>
            <span>{{ $t("Profile") }}</span>
          </v-tab>
          <v-tab
            value="map"
            href="#map"
            v-if="$can('view-map', 'User')"
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ mdiMap }}
            </v-icon>
            <span>{{ $t("Map") }}</span>
          </v-tab>
          <v-tab
            v-if="$can('read', 'Subscription')"
            value="subscriptions"
            href="#subscriptions"
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ mdiCart }}
            </v-icon>
            <span>{{ $t("Subscriptions") }}</span>
          </v-tab>
          <v-tab
            value="activités"
            href="#activités"
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ mdiAccountOutline }}
            </v-icon>
            <span>{{ $t("Activity history") }}</span>
          </v-tab>
          <v-tab
            value="parameters"
            href="#parameters"
            v-if="userData.id === $store.state.app.user.id || $can('manage', 'all')"
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ mdiAccountCogOutline }}

            </v-icon>
            <span>{{ $t("Paramenters") }}</span>
          </v-tab>
          <!--          <v-tab
                      value="notifications"
                      href="#notifications"
                      v-if="userData.id === $store.state.app.user.id || $can('manage', 'all')"
                    >
                      <v-icon
                        size="20"
                        class="me-3"

                      >
                        {{ mdiAccountCogOutline }}
                      </v-icon>
                      <span>{{ $t("Notifications") }}</span>
                    </v-tab>
                    -->
          <v-tab
            value="security"
            href="#security"
            v-if="userData.id === $store.state.app.user.id || $can('manage', 'all')"
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ mdiLockOutline }}
            </v-icon>
            <span>{{ $t("Security") }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items
          id="user-tabs-content"
          v-model="userTab"
          class="mt-5 pa-1"
        >
          <v-tab-item
            key="profile"
            value="profile"
          >
            <v-row>
              <v-col cols="12" md="3" lg="3">
                <user-bio-panel
                  :user-data="userData"
                  :is-plan-upgrade-dialog-visible.sync="isPlanUpgradeDialogVisible"
                ></user-bio-panel>
              </v-col>

            </v-row>
          </v-tab-item>
          <v-tab-item
            key="map"
            value="map"
            v-if="$can('view-map', 'User')"
          >
            <v-row>
              <v-col cols="12"  >
                <user-map-panel :user-data="userData" />
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item
            v-if="$can('read', 'Subscription')"
            key="subscriptions"
            value="subscriptions"
          >
            <!--            <author-tab-overview></author-tab-overview>-->
            <!--            <activities />-->
            <Subscriptions />
          </v-tab-item>
          <v-tab-item value="activités" key="activités">
            <!--            <user-tab-overview></user-tab-overview>-->
            <activities />
          </v-tab-item>

          <v-tab-item value="parameters" v-if="userData.id === $store.state.app.user.id || $can('manage', 'all')">
            <account-settings-account :account-data="userData"></account-settings-account>
          </v-tab-item>

          <v-tab-item value="security"  v-if="userData.id === $store.state.app.user.id || $can('manage', 'all')">
            <user-tab-security></user-tab-security>
          </v-tab-item>
          <!--          <v-tab-item>
            <user-tab-billings-plans :is-plan-upgrade-dialog-visible.sync="isPlanUpgradeDialogVisible"></user-tab-billings-plans>
          </v-tab-item>
          <v-tab-item>
            <user-tab-notifications></user-tab-notifications>
          </v-tab-item>
          <v-tab-item>
            <user-tab-connections></user-tab-connections>
          </v-tab-item>-->
        </v-tabs-items>

      </v-col>
    </v-row>

    <v-dialog
      v-model="isPlanUpgradeDialogVisible"
      max-width="650"
    >
      <v-card class="py-8">
        <v-card-title class="justify-center text-h5 px-5">
          Upgrade Plan
        </v-card-title>
        <v-card-text class="text-center mt-n2 px-5">
          Choose the best plan for user.
        </v-card-text>
        <v-card-text class="d-flex align-center flex-wrap flex-sm-nowrap mt-5 px-15">
          <v-select
            v-model="selectedPlan"
            label="Choose Plan"
            :items="plansList"
            item-text="text"
            item-value="value"
            outlined
            dense
            hide-details
            class="me-3"
          ></v-select>
          <v-btn
            color="primary"
            class="mt-3 mt-sm-0"
          >
            Upgrade
          </v-btn>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text class="px-15 pt-8">
          <p class="font-weight-medium text--primary mb-2">
            User current plan is {{ userData.currentPlan }} plan
          </p>
          <div class="d-flex justify-space-between flex-wrap">
            <div class="user-pricing me-3">
              <sup class="primary--text">$</sup>
              <span class="text-5xl font-weight-semibold primary--text">{{ resolveCurrentPlanValue(userData.currentPlan) }}</span>
              <sub class="text-base font-weight-light">/ month</sub>
            </div>
            <v-btn
              color="error"
              outlined
              class="mt-3"
            >
              Cancel Subscription
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { onUnmounted, ref } from '@vue/composition-api'
import {
  mdiAccountOutline, mdiLockOutline, mdiBookmarkOutline, mdiBellOutline, mdiLinkVariant, mdiAccountCogOutline,mdiCart, mdiMap,
} from '@mdi/js'
import router from '@/router'
import store from '@/store'

// eslint-disable-next-line object-curly-newline
import UserBioPanel from './user-bio-panel/UserBioPanel.vue'
import userStoreModule from '../userStoreModule'
import UserTabOverview from './user-tab-overview/UserTabOverview.vue'
import UserTabSecurity from './user-tab-security/UserTabSecurity.vue'

/* import UserTabBillingsPlans from './user-tab-billings-plans/UserTabBillingsPlans.vue'
import UserTabNotifications from './user-tab-notifications/UserTabNotifications.vue'
import UserTabConnections from './user-tab-connections/UserTabConnections.vue' */
import AccountSettingsAccount from '@/views/pages/account-settings/AccountSettingsAccount.vue'
import Activities from '@/views/apps/user/user-view/user-tab-overview/Activities'
import Subscriptions from "@/views/apps/subscriptions/Subscriptions";

import UserMapPanel from "@/views/apps/user/user-view/user-map-panel/UserMapPanel";

export default {
  components: {
    UserMapPanel,
    Subscriptions,
    Activities,
    AccountSettingsAccount,
    UserBioPanel,
    UserTabOverview,
    UserTabSecurity,

    /* UserTabBillingsPlans,
    UserTabNotifications,
    UserTabConnections, */
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const userData = ref({})
    const dateRage = ref()
    const userTab = ref(null)
    const selectedPlan = ref('')
    const isPlanUpgradeDialogVisible = ref(false)

    if (router.currentRoute.params.id) {
      store
        .dispatch('app-user/fetchUser', { id: router.currentRoute.params.id })
        .then(response => {
          console.log('response',response.data)
          userData.value = response.data
        })
        .catch(error => {
          if (error.response.status === 404) {
            userData.value = {}
          }
        })
    } else {
      userData.value = JSON.parse(localStorage.getItem('userData'))
    }

    const plansList = [
      { text: 'Basic - $0/month', value: 'basic' },
      { text: 'Standard - $99/month', value: 'standard' },
      { text: 'Enterprise - $499/month', value: 'enterprise' },
      { text: 'Company - $999/month', value: 'company' },
    ]

    const tabs = [
      { icon: mdiAccountOutline, title: 'Historique des activités' },
      { icon: mdiAccountCogOutline, title: 'Paramètres' },
      { icon: mdiLockOutline, title: 'Sécurité' },

      /* { icon: mdiBookmarkOutline, title: 'Billing & Plan' },
      { icon: mdiBellOutline, title: 'Notifications' },
      { icon: mdiLinkVariant, title: 'Connections' }, */
    ]

    // ui
    const resolveCurrentPlanValue = plan => {
      if (plan === 'basic') return '0'
      if (plan === 'standard') return '99'
      if (plan === 'enterprise') return '499'
      if (plan === 'company') return '999'

      return '0'
    }

    return {
      dateRage,
      tabs,
      userTab,
      plansList,
      selectedPlan,
      isPlanUpgradeDialogVisible,
      userData,
      resolveCurrentPlanValue,
      mdiAccountOutline,mdiAccountCogOutline,mdiLockOutline,mdiCart,mdiMap
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
