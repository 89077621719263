<template>
  <v-card>
    <v-card-text>
      <date-range-picker
        v-model="dateRange"
        label="Date"
        :max-date="null"
        first-day-of-week="1"
        no-title
        scrollable
        :type="'date'"
        style="min-width: 67px"
      />
    </v-card-text>
    <l-map
      :zoom.sync="zoom"
      :options="mapOptions"
      :center="center"
      :bounds="bounds"

      style="height: 700px; width: 100%"
    >
      <l-control-layers
        :position="layersPosition"
        :collapsed="false"
        :sort-layers="true"
      />
      <l-tile-layer
        v-for="tileProvider in tileProviders"
        :key="tileProvider.name"
        :name="tileProvider.name"
        :visible="tileProvider.visible"
        :url="tileProvider.url"
        :attribution="tileProvider.attribution"
        :token="token"
        layer-type="base"
      />
      <l-control-zoom :position="zoomPosition" />
      <l-control-attribution
        :position="attributionPosition"
        :prefix="attributionPrefix"
      />
      <l-control-scale :imperial="imperial" />
      <l-marker
        v-for="marker in markers"
        :key="marker.id"
        :visible="marker.visible"
        :draggable="marker.draggable"
        :lat-lng.sync="marker.position"
        :icon="marker.icon"
      >
        <l-popup>
          <avatar-name :options="{avatar: marker.avatar, label: marker.label }" />
        </l-popup>
        <l-tooltip :content="marker.tooltip" />
      </l-marker>
      <!--      <l-layer-group
        layer-type="overlay"
        name="Layer polyline"
      >
        <l-polyline
          v-for="item in polylines"
          :key="item.id"
          :lat-lngs="item.points"
          :visible="item.visible"
          @click="alert(item)"
        />
      </l-layer-group>
      <l-layer-group
        v-for="item in stuff"
        :key="item.id"
        :visible.sync="item.visible"
        layer-type="overlay"
        name="Layer 1"
      >
        <l-layer-group :visible="item.markersVisible">
          <l-marker
            v-for="marker in item.markers"
            :key="marker.id"
            :visible="marker.visible"
            :draggable="marker.draggable"
            :lat-lng="marker.position"
            @click="alert(marker)"
          />
        </l-layer-group>
        <l-polyline
          :lat-lngs="item.polyline.points"
          :visible="item.polyline.visible"
          @click="alert(item.polyline)"
        />
      </l-layer-group>-->
    </l-map>
  </v-card>
</template>

<script>
import {
  mdiCheck, mdiBriefcaseVariantOutline, mdiCheckboxBlankCircle, mdiPencil,
} from '@mdi/js'
import { avatarText, kFormatter } from '@core/utils/filter'
import { ref, watch } from '@vue/composition-api'

/* eslint-disable global-require */

// eslint-disable-next-line object-curly-newline
import {
  LMap,
  LTileLayer,
  LMarker,
  LPolyline,
  LLayerGroup,
  LTooltip,
  LPopup,
  LControlZoom,
  LControlAttribution,
  LControlScale,
  LControlLayers,
} from 'vue2-leaflet'
import { icon, Icon, latLngBounds } from 'leaflet'
import controller from '../../user-list/useUsersList'
import 'leaflet/dist/leaflet.css'
import store from '@/store'
import HeaderFilters from '@/components/HeaderFilters'
import DatePicker from '@/components/DatePicker'
import DateRangePicker from '@/components/DateRangePicker'
import AvatarName from '@/components/AvatarName'

// eslint-disable-next-line no-underscore-dangle
delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})
/* eslint-disable global-require */
/* eslint-disable global-require */

const markers1 = [
  {
    position: { lng: -1.219482, lat: 47.41322 },
    visible: true,
    draggable: true,
  },
  { position: { lng: -1.571045, lat: 47.457809 } },
  { position: { lng: -1.560059, lat: 47.739323 } },
  { position: { lng: -0.922852, lat: 47.886881 } },
  { position: { lng: -0.769043, lat: 48.231991 } },
  { position: { lng: 0.395508, lat: 48.268569 } },
  { position: { lng: 0.604248, lat: 48.026672 } },
  { position: { lng: 1.2854, lat: 47.982568 } },
  { position: { lng: 1.318359, lat: 47.894248 } },
  { position: { lng: 1.373291, lat: 47.879513 } },
  { position: { lng: 1.384277, lat: 47.798397 } },
  { position: { lng: 1.329346, lat: 47.754098 } },
  { position: { lng: 1.329346, lat: 47.680183 } },
  { position: { lng: 0.999756, lat: 47.635784 } },
  { position: { lng: 0.86792, lat: 47.820532 } },
  { position: { lng: 0.571289, lat: 47.820532 } },
  { position: { lng: 0.439453, lat: 47.717154 } },
  { position: { lng: 0.439453, lat: 47.61357 } },
  { position: { lng: -0.571289, lat: 47.487513 } },
  { position: { lng: -0.615234, lat: 47.680183 } },
  { position: { lng: -0.812988, lat: 47.724545 } },
  { position: { lng: -1.054688, lat: 47.680183 } },
  { position: { lng: -1.219482, lat: 47.41322 } },
]

const poly1 = [
  { lng: -1.219482, lat: 47.41322 },
  { lng: -1.571045, lat: 47.457809 },
  { lng: -1.560059, lat: 47.739323 },
  { lng: -0.922852, lat: 47.886881 },
  { lng: -0.769043, lat: 48.231991 },
  { lng: 0.395508, lat: 48.268569 },
  { lng: 0.604248, lat: 48.026672 },
  { lng: 1.2854, lat: 47.982568 },
  { lng: 1.318359, lat: 47.894248 },
  { lng: 1.373291, lat: 47.879513 },
  { lng: 1.384277, lat: 47.798397 },
  { lng: 1.329346, lat: 47.754098 },
  { lng: 1.329346, lat: 47.680183 },
  { lng: 0.999756, lat: 47.635784 },
  { lng: 0.86792, lat: 47.820532 },
  { lng: 0.571289, lat: 47.820532 },
  { lng: 0.439453, lat: 47.717154 },
  { lng: 0.439453, lat: 47.61357 },
  { lng: -0.571289, lat: 47.487513 },
  { lng: -0.615234, lat: 47.680183 },
  { lng: -0.812988, lat: 47.724545 },
  { lng: -1.054688, lat: 47.680183 },
  { lng: -1.219482, lat: 47.41322 },
]

const tileProviders = [
  {
    name: 'OpenStreetMap',
    visible: true,
    attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
  },
  {
    name: 'OpenTopoMap',
    visible: false,
    url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
    attribution:
      'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
  },
]

export default {
  components: {
    AvatarName,
    DateRangePicker,
    DatePicker,
    HeaderFilters,
    LMap,
    LTileLayer,
    LMarker,
    LPolyline,
    LLayerGroup,
    LTooltip,
    LPopup,
    LControlZoom,
    LControlAttribution,
    LControlScale,
    LControlLayers,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    isPlanUpgradeDialogVisible: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const { resolveUserStatusVariant, resolveUserStatusName, resolveUserRoleVariant } = controller()

    const filters = ref([
      {
        text: 'User',
        value: 'user.name',
        object: 'user',
        type: 'select',
        fixed: true,
        required: true,
        info: 'Confirm the update of "Owner" ?',
        requiredMessage: 'Shop owner is required',
        width: '150px',
        selected_value: 'user_id',
        selected_label: 'name',
        itemLabel: 'name',
        itemValue: 'id',
        items: [],
        source: '/users',
        loading: false,
        search: '',
        editable: false,
        column_name: 'user_id',
        sm: 12,
        md: 6,
        lg: 6,
        xl: 6,
        fields: [
          {
            type: 'select',
            label: 'User',
            value: 'user_id',
            items: [],
            source: '/users',
            item_text: 'name',
            item_value: 'id',
          },
        ],
        filter: {
          place_holder: '',
          type: 'select',
          source: '/users',
          label: 'name',
          id: 'id',
          multiple: true,
          items: [],
          key: 'location.user_id',
          column: 'locations.user_id',
          join_table: 'users',
          join_column: 'users.id',
          local_column: 'locations.user_id',
        },
      },
      {
        text: 'Actions',
        value: 'actions',
        align: 'center',
        sortable: false,
      },
    ])
    const dateRange = ref(null)
    const center = ref([51.505, -0.09])
    const opacity = 0.6
    const token = 'your token if using mapbox'
    const mapOptions = {
      zoomControl: true,
      attributionControl: true,
      zoomSnap: false,
    }
    const zoom = 10
    const minZoom = 1
    const maxZoom = 20
    const zoomPosition = 'topleft'
    const attributionPosition = 'bottomright'
    const layersPosition = 'topright'
    const attributionPrefix = 'Vue2Leaflet'
    const imperial = false
    const Positions = ['topleft', 'topright', 'bottomleft', 'bottomright']
    const markers = ref([
      {
        id: 'm1',
        position: { lat: 4.079212, lng: 9.749522 },
        tooltip: 'tooltip for marker1',
        draggable: false,
        visible: false,
      },
    ])

    const polylines = [
      {
        id: 'p1',
        points: [
          { lat: 37.772, lng: -122.214 },
          { lat: 21.291, lng: -157.821 },
          { lat: -18.142, lng: -181.569 },
          { lat: -27.467, lng: -206.973 },
        ],
        visible: true,
      },
      {
        id: 'p2',
        points: [
          [-73.91, 40.78],
          [-87.62, 41.83],
          [-96.72, 32.76],
        ],
        visible: true,
      },
    ]

    const stuff = [
      {
        id: 's1',
        markers: markers1,
        polyline: { points: poly1, visible: true },
        visible: true,
        markersVisible: true,
      },
    ]
    const bounds = ref(
      latLngBounds(
        { lat: 4.079212, lng: 9.749522 },
        { lat: 4.079212, lng: 9.749522 },
      ),
    )

    const alert = item => {
      // eslint-disable-next-line no-unused-vars
      alert(`this is ${JSON.stringify(item)}`)
    }

    const addMarker = () => {
      const newMarker = {
        position: { lat: 50.5505, lng: -0.09 },
        draggable: true,
        visible: true,
      }
      markers.value.push(newMarker)
    }

    const removeMarker = index => {
      markers.value.splice(index, 1)
    }

    const fitPolyline = () => {
      const boundsLocal = latLngBounds(markers.value.map(o => o.position))
      bounds.value = boundsLocal
    }

    const fetchMarkers = first => {
      store.dispatch('app-user/fetchLocations', {
        user: props.userData.id,
        date: dateRange.value,
      })
        .then(response => {
          const { data } = response.data
          markers.value = data.map(m => ({
            id: `${m.id}`,
            position: { lat: m.latitude, lng: m.longitude },
            tooltip: m.user ? m.user.name : `${m.user_id}`,
            draggable: false,
            visible: true,
            label: m.user ? m.user.name : `${m.user_id}`,
            avatar: m.user ? m.user.avatar : null,
            icon: m.user ? icon({
              iconUrl: m.user ? m.user.avatar : null,
              iconSize: [15, 15],
              iconAnchor: [10, 15],
            }) : null,
          }))
          if (first) {
            fitPolyline()
          }

          setTimeout(() => {
            fetchMarkers()
          }, 10000)
          // eslint-disable-next-line no-unused-vars
        }).catch(error => {

        })
    }
    fitPolyline()
    fetchMarkers(true)
    watch([dateRange], () => {
      fetchMarkers()
    }, { deep: true })

    return {
      dateRange,
      filters,
      center,
      opacity,
      token,
      mapOptions,
      zoom,
      minZoom,
      maxZoom,
      zoomPosition,
      attributionPosition,
      layersPosition,
      attributionPrefix,
      imperial,
      Positions,
      tileProviders,
      markers,
      polylines,
      stuff,
      bounds,
      alert,
      addMarker,
      removeMarker,
      fitPolyline,
    }
  },
}
</script>

<style lang="scss">
.vue2leaflet-map {
  &.leaflet-container {
    height: 350px;
  }
}
</style>
